import { navigate } from 'gatsby';
import * as React from 'react';
import { useStoryBlockCommon } from '../lib/storyblok/storyblok';

const IndexPage = () => {
    const storyblokCommon = useStoryBlockCommon(process.env.GATSBY_COUNTRY ?? '');

    if (typeof window !== 'undefined') {
        const homePage = `/${storyblokCommon.topNavigation.content.home_link.cached_url}`;
        navigate(homePage);
    }

    return <></>;
};

export default IndexPage;
